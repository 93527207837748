import React from 'react';
import Loader from '../loader';
import ReviewCard from '../review-card';
import {IReviewDialogue} from '../../redux/review/interfaces';

import './styles.scss';

type Props = {
  cards: IReviewDialogue[];
  showLoader?: boolean;
  onLoadMore?: (count: number) => void;
  onClickCard: (review: IReviewDialogue) => void;
  checkedCards?: number[];
  disabledButtons?: boolean;
}

export const ReviewsCardSection = ({
  showLoader = false,
  cards,
  onClickCard
}: Props): JSX.Element => {
  function handleClickCard(review: IReviewDialogue) {
    return () => {
      onClickCard(review);
    };
  }

  return <section className="reviews-card-section">
    {showLoader ? <Loader/> : <ul className="reviews-card-section__list">
      {
        cards && cards.length && cards.map((card, i) => {
          return <li key={card.dialogId} className="reviews-card-section__item">
            <ReviewCard
              card={card}
              onClick={handleClickCard(card)}/>
          </li>;
        })
      }
    </ul>}
  </section>;
};
