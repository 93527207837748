import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';

import {IStore} from 'redux/interface';
import {getReview, getTranslations, reviewAsyncActions} from 'redux/pageAsyncActions';
import {useGetTranslations} from 'utils/hooks/useGetTranslations';
import {BotType} from 'utils/hooks/use-chat-bot/interfaces';
import {TranslationKeys} from 'utils/constants/routeConstants';

import BotChat from 'components/bot-chat';

import {closeArticle} from 'redux/content/actions';
import {ReactComponent as XpeditionIcon} from 'assets/image/menu-auth/xpedition.svg';
import {Link, useParams} from 'react-router-dom';

import './styles.scss';

export const ReviewChat: React.FunctionComponent = () => {
  const {
    status: {loading},
    review: {
      review
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const {reviewId} = useParams();

  useEffect(() => {
    dispatch(closeArticle());
  }, []);

  useEffect(() => {
    if (reviewId && (!review || review.dialogId !== Number(reviewId))) {
      dispatch(reviewAsyncActions[getReview](Number(reviewId)));
    }
  }, []);

  useGetTranslations({
    translationKey: TranslationKeys.Chat
  });

  function renderBreadcrumbs() {
    return <div className="breadcrumbs">
      <Link className="breadcrumbs__previous" to="/reviews">
        <FormattedMessage
          id={'gritx.review.breadcrumb.previous'}
          defaultMessage="Reviews"
        />
      </Link> {review && <span className="review__title-dialogue-name"> - {review.dialogName}</span>}
    </div>;
  }

  return <div className="review">
    {
      !loading.includes(getTranslations) && review && <>
        <h1 className="review__title">
          <XpeditionIcon className="review__icon"/>
          {renderBreadcrumbs()}
        </h1>
        <BotChat mode={BotType.Xpedition} dialogueId={review.dialogId} title={review.dialogName} />
      </>
    }
  </div>;
};
