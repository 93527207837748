import React, {useEffect, useState} from 'react';
import {IJournalStatisticValue} from '../../../redux/journal-stats/interfaces';
import {PieChart} from '@mui/x-charts/PieChart';
import {PieValueType} from '@mui/x-charts';
import {MakeOptional} from '@mui/x-charts/models/helpers';

import './styles.scss';

interface IJournalStatValues {
  values: IJournalStatisticValue[]
  name: string
}

export const JournalPieChart: React.FunctionComponent<IJournalStatValues> = ({values, name}: IJournalStatValues) => {
  const [chartData, setChartData] = useState<MakeOptional<PieValueType, 'id'>[]>([]);

  useEffect(() => {
    setChartData(values
      .map((v, i) => {
        return {
          id: i,
          value: v.value,
          label: v.xscName
        };
      }));
  }, [values]);

  return <div className="stats__values-pie-chart">
    <div className="stats__values-pie-chart-name">{name}</div>
    <PieChart
      colors={['#c1e6d9', '#78c7eb', '#eba378', '#5b5aa6', '#84a58b', '#f8f0cc', '#d66027']}
      series={[
        {
          data: chartData,
          arcLabel: (p) => p.value ? p.value.toString() : ''
        }
      ]}
      margin={{top: 10, bottom: 10}}
      height={300}
    />
  </div>;
};
