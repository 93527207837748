import {
  SET_REVIEW_TYPES,
  ReviewActionsType,
  CLEAR_REVIEW,
  SET_REVIEWS, SET_REVIEW
} from './actions';
import {
  IReviewInitialState,
  IReviewActionHandler, IReviewType, IReviewDialogue
} from './interfaces';

const ReviewInitialState: IReviewInitialState = {
  types: [],
  reviews: [],
  review: null
};

const setReviewTypes = ({
  state,
  payload
}: IReviewActionHandler): IReviewInitialState => {
  return {
    ...state,
    types: payload as IReviewType[]
  };
};

const setReviews = ({
  state,
  payload
}: IReviewActionHandler): IReviewInitialState => {
  return {
    ...state,
    reviews: payload as IReviewDialogue[]
  };
};

const setReview = ({
  state,
  payload
}: IReviewActionHandler): IReviewInitialState => {
  return {
    ...state,
    review: payload as IReviewDialogue
  };
};

const clearReview = ({
  state
}: IReviewActionHandler): IReviewInitialState => {
  return {
    ...state,
    review: null
  };
};

const reviewReducerHandlers = new Map([
  [SET_REVIEW_TYPES, setReviewTypes],
  [CLEAR_REVIEW, clearReview],
  [SET_REVIEWS, setReviews],
  [SET_REVIEW, setReview]
]);

const reviewReducer = (state = ReviewInitialState, action: ReviewActionsType): IReviewInitialState => {
  const handler = reviewReducerHandlers.get(action.type);

  return (handler && {
    ...handler(<IReviewActionHandler>{
      state,
      payload: action.payload
    })
  }) || {...state};
};

export default reviewReducer;
