import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import {ConfirmationModalWindow} from '../confirmation-modal-window/ConfirmationModalWindow';
import {ButtonVariant, GritxButton} from '@wholesalechange/chatcomponent';
import {
  editReview,
  removeReview,
  reviewAsyncActions
} from '../../redux/pageAsyncActions';
import {IReviewDialogue} from '../../redux/review/interfaces';
import ReviewModalWindow from '../review-modal-window';
import {useLoadFile} from '../../utils/hooks/useLoadFile';
import {useIntl} from 'react-intl';

import './styles.scss';

type Props = {
  card: IReviewDialogue;
  onClick: () => void;
}

export const ReviewCard = ({
  card,
  onClick
}: Props): JSX.Element => {
  const dispatch = useDispatch();
  const [cardData, setCardData] = useState<IReviewDialogue>(card);
  const [isShowEditDialogue, setShowEditDialogue] = useState<boolean>(false);
  const [isShowDeleteDialogue, setShowDeleteDialogue] = useState<boolean>(false);
  const [image, setImage] = useState<string>();
  const {
    media,
    getFileUrl
  } = useLoadFile();
  const intl = useIntl();

  useEffect(() => {
    if (cardData.imageUrl) {
      setImage(cardData.imageUrl);
    } else if (cardData.imageId) {
      setImage(getFileUrl(cardData.imageId));
    }
  }, [media]);

  function handleOpenEditDialogueModal() {
    setShowEditDialogue(true);
  }

  useEffect(() => {
    setCardData(card);
  }, [card]);

  function handleEditDialogue(newReview: IReviewDialogue) {
    dispatch(reviewAsyncActions[editReview](newReview));
    setCardData(newReview);
    setShowEditDialogue(false);
  }

  function handleDeleteDialogue() {
    dispatch(reviewAsyncActions[removeReview](cardData));
  }

  function handleCloseDialogue() {
    setShowEditDialogue(false);
  }

  function handleOpenDeleteDialogue() {
    setShowDeleteDialogue(true);
  }

  function handleCloseDeleteDialogue() {
    setShowDeleteDialogue(false);
  }

  function handleClick() {
    onClick();
  }

  function renderBasicCard() {
    return <div className="review-area">
      <div className="review-area__card-place">
        <div className="review-area__card">
          <div className="review-area__title">
            <h2 className="review-area__title-wrapper">
              <button className="review-area__title__text" onClick={handleClick}>{cardData.dialogName}</button>
            </h2>
          </div>
          <div className="review-area__image-wrapper">
            <img src={image} role="presentation" alt="" className="review-area__image"/>
          </div>
          <div className="review-area__title-percent" style={{
            background: `linear-gradient(to left, #f2f2f2 ${100 - cardData.percentage}%, #EBA378 ${100 - cardData.percentage}%)`
          }}>
          </div>
        </div>
        <div className="review-area__bottom">
          <div className="review-area__btn">
            <GritxButton
              title={intl.formatMessage({
                id: 'gritx.review.card.rename',
                defaultMessage: 'Rename'
              })}
              variant={ButtonVariant.Outline}
              disabled={false}
              className="review-area__button"
              onClick={handleOpenEditDialogueModal}
            />
          </div>
          <div className="review-area__btn">
            <GritxButton
              title={intl.formatMessage({
                id: 'gritx.review.card.delete',
                defaultMessage: 'Delete'
              })}
              variant={ButtonVariant.Outline}
              disabled={cardData.percentage === 100}
              className="review-area__button"
              onClick={handleOpenDeleteDialogue}
            />
          </div>
        </div>
      </div>
      <ReviewModalWindow
        card={card}
        types={[]}
        title={intl.formatMessage({
          id: 'gritx.review.card.rename.title',
          defaultMessage: 'Rename the review'
        })}
        onSave={handleEditDialogue}
        onClose={handleCloseDialogue}
        show={isShowEditDialogue}
      />
      <ConfirmationModalWindow
        title={intl.formatMessage({
          id: 'gritx.review.card.delete.confirm',
          defaultMessage: 'Are you sure you want to delete the review?'
        })}
        onSave={handleDeleteDialogue}
        onClose={handleCloseDeleteDialogue}
        show={isShowDeleteDialogue}
      />
    </div>;
  }

  return renderBasicCard();
};
