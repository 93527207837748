import {Auth0Client} from '@auth0/auth0-spa-js';

let auth0Instance: Auth0Client;

if (process.env.NODE_ENV !== 'test') {
  auth0Instance = new Auth0Client({
    domain: process.env.REACT_APP_AUTH0_DOMAIN as string,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID as string,
    cacheLocation: 'localstorage',
    useRefreshTokens: true,
    authorizationParams: {
      redirect_uri: `${window.location.origin}`
    }
  });
} else {
  auth0Instance = {
    getUser: jest.fn(),
    getIdTokenClaims: jest.fn(),
    loginWithRedirect: jest.fn(),
    handleRedirectCallback: jest.fn(),
    checkSession: jest.fn(),
    getTokenSilently: ()=>Promise.resolve('token'),
    getTokenWithPopup: jest.fn(),
    isAuthenticated: ()=>Promise.resolve(true),
    logout: jest.fn()
  } as unknown as Auth0Client;
}
export default auth0Instance;
