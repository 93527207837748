import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {FormattedMessage, useIntl} from 'react-intl';

import {IStore} from 'redux/interface';
import {
  createReview,
  getDialogues,
  getReviews,
  getReviewTypes,
  getTranslations,
  removeReview,
  reviewAsyncActions
} from 'redux/pageAsyncActions';
import {useGetTranslations} from 'utils/hooks/useGetTranslations';
import {TranslationKeys} from 'utils/constants/routeConstants';

import {ReactComponent as ChatIcon} from 'assets/image/menu-auth/chat.svg';
import {ButtonVariant, GritxButton} from '@wholesalechange/chatcomponent';
import NotFoundInfo from '../../components/not-found-info';
import CrossroadImg from '../../assets/image/library/skill-crossroad.svg';
import Loader from '../../components/loader';
import {useNavigate} from 'react-router-dom';
import {IRadioInput} from '../../components/input-radio/interfaces';
import InputRadio from '../../components/input-radio';
import {IReviewDialogue} from '../../redux/review/interfaces';
import {clearReview, setReview} from '../../redux/review/actions';
import {ReviewsCardSection} from '../../components/reviews-card-section/ReviewsCardSection';
import ReviewModalWindow from '../../components/review-modal-window';

import './styles.scss';

export const Reviews: React.FunctionComponent = () => {
  const {
    review: {
      reviews,
      types
    },
    status: {
      loading
    }
  } = useSelector((state: IStore) => state);
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sortedReviews, setSortedReviews] = useState<IReviewDialogue[]>([]);
  const [sort, setSort] = useState<string>('new');
  const [newReview, setNewReview] = useState<IReviewDialogue | null>(null);
  const [isShowCreateDialogue, setShowCreateDialogue] = useState(false);

  useEffect(() => {
    dispatch(clearReview());
  }, []);

  const sortRadioInputs: IRadioInput[] = [
    {
      id: 0,
      name: 'new',
      label: 'Newest',
      value: 'new'
    },
    {
      id: 1,
      name: 'old',
      label: 'Oldest',
      value: 'old'
    }];

  function getTime(date?: Date) {
    return date != null ? new Date(date).getTime() : 0;
  }

  function getSortedReviews(sortMethod: string) {
    const reviewsCopy = reviews.slice(0);

    if (sortMethod === 'old') {
      return reviewsCopy.sort((a, b) => getTime(a.dialogUserDate) - getTime(b.dialogUserDate));
    }

    return reviewsCopy.sort((a, b) => getTime(b.dialogUserDate) - getTime(a.dialogUserDate));
  }

  useEffect(() => {
    if (reviews) {
      setSortedReviews(getSortedReviews(sort));
    }
  }, [reviews, sort]);

  useGetTranslations({
    translationKey: TranslationKeys.Reviews
  });

  useEffect(() => {
    dispatch(reviewAsyncActions[getReviews]());
    dispatch(reviewAsyncActions[getReviewTypes]());
  }, []);

  function handleOpenDialogue(reviewDialogue: IReviewDialogue) {
    dispatch(setReview(reviewDialogue));
    navigate(`/review/${reviewDialogue.dialogId}`);
  }

  function onCreateReview() {
    setNewReview({
      dialogUserDate: new Date(),
      dialogName: '',
      percentage: 0,
      dialogId: 0,
      imageId: 0,
      imageUrl: '',
      xpeditionId: 0
    });

    setShowCreateDialogue(true);
  }

  function handleCloseDialogue() {
    setNewReview(null);
    setShowCreateDialogue(false);
  }

  function handleCreateDialogue(card: IReviewDialogue) {
    dispatch(reviewAsyncActions[createReview](card));
    setShowCreateDialogue(false);
  }

  function handleChangeSort(value: string) {
    setSort(value);
  }

  function renderReviewCards() {
    return <div className="reviews__cards">
      {sortedReviews.length === 0 ? <NotFoundInfo
        title={intl.formatMessage({
          id: 'gritx.review.sectionCards.notFound',
          defaultMessage: 'Sorry, but nothing can be found.'
        })}
        image={CrossroadImg}
      /> : <ReviewsCardSection
        showLoader={loading.includes(getDialogues)}
        cards={sortedReviews}
        onClickCard={handleOpenDialogue}
      />}
    </div>;
  }

  return <section className={'reviews reviews__container'}>
    {
      !loading.includes(getTranslations) && <>
        <h1 className="reviews__title">
          <ChatIcon className="reviews__icon"/>
          <FormattedMessage
            id={'gritx.review.title'}
            defaultMessage={'Reviews'}
          />
        </h1>
        <div className="reviews__header">
          <div className="reviews__header-sort">
            <InputRadio inputs={sortRadioInputs}
              value={sort}
              onChange={handleChangeSort}
              label={intl.formatMessage({
                id: 'gritx.review.sort.title',
                defaultMessage: 'Sort by:'
              })}/>
          </div>
          <GritxButton
            title={intl.formatMessage({
              id: 'gritx.review.create',
              defaultMessage: 'Create'
            })}
            className={'reviews__button'}
            variant={ButtonVariant.Primary}
            onClick={onCreateReview}
          />
        </div>
        {
          loading.includes(getReviews) || loading.includes(removeReview)
          || loading.includes(createReview) ? <Loader/> : renderReviewCards()
        }
        {newReview && <ReviewModalWindow
          card={newReview}
          types={types}
          title={intl.formatMessage({
            id: 'gritx.review.create.title',
            defaultMessage: 'Create the review'
          })}
          onSave={handleCreateDialogue}
          onClose={handleCloseDialogue}
          show={isShowCreateDialogue}
        />}
      </>
    }
  </section>;
};
