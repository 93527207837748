import {ThunkAction} from 'redux-thunk';
import {IStore} from '../interface';
import {Action} from 'redux';
import {
  createReview,
  editReview,
  getReview,
  getReviews,
  getReviewTypes,
  removeReview
} from '../pageAsyncActions';
import {clearErrorAction, finishLoadingAction, startLoadingAction} from '../status/actions';
import {
  setReview,
  setReviews,
  setReviewTypes
} from './actions';
import {errorHandler} from '../errorHandler';
import {ToastKey} from '../../components/gritx-toast/ToastKeyEnum';
import {createReviewApi, editReviewApi, getReviewApi, getReviewsApi, getReviewTypesApi} from '../api/review';
import {IReviewDialogue} from './interfaces';
import {removeDialogueApi} from '../api/dialogue';

export const loadReviewTypesAction = (): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    auth: {auth0Client}
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = getReviewTypes;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      const {data} = await getReviewTypesApi();

      dispatch(finishLoadingAction(actionKey));
      dispatch(setReviewTypes(data));
    } catch (e) {
      console.log('loadReviewTypesAction', e);
      dispatch(errorHandler({
        toastKey: ToastKey.LoadReviewTypeAction,
        actionKey,
        error: e
      }));
    }
  }
});

export const loadReviewsAction = (): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    auth: {auth0Client}
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = getReviews;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      const {data} = await getReviewsApi();

      dispatch(finishLoadingAction(actionKey));
      dispatch(setReviews(data));
    } catch (e) {
      console.log('LoadReviewsAction', e);
      dispatch(errorHandler({
        toastKey: ToastKey.LoadReviewsAction,
        actionKey,
        error: e
      }));
    }
  }
});

export const getReviewAction = (id: number): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    auth: {auth0Client}
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = getReview;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      const {data} = await getReviewApi(id);

      dispatch(finishLoadingAction(actionKey));
      dispatch(setReview(data));
    } catch (e) {
      console.log('LoadReviewAction', e);
      dispatch(errorHandler({
        toastKey: ToastKey.LoadReviewAction,
        actionKey,
        error: e
      }));
    }
  }
});

export const createReviewAction = (review: IReviewDialogue): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    translation: {locale},
    auth: {auth0Client}
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = createReview;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      const {data} = await createReviewApi(review, locale);

      dispatch(loadReviewsAction());
      dispatch(finishLoadingAction(actionKey));
    } catch (e) {
      console.log('createReviewAction', e);
      dispatch(finishLoadingAction(actionKey));

      dispatch(errorHandler({
        toastKey: ToastKey.CreateReviewAction,
        actionKey,
        error: e
      }));
    }
  }
});

export const editReviewAction = (review: IReviewDialogue): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    auth: {auth0Client}
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = editReview;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      await editReviewApi(review);
      dispatch(loadReviewsAction());
      dispatch(finishLoadingAction(actionKey));
    } catch (e) {
      console.log('editReviewAction', e);
      dispatch(finishLoadingAction(actionKey));

      dispatch(errorHandler({
        toastKey: ToastKey.EditReviewAction,
        actionKey,
        error: e
      }));
    }
  }
});

export const removeReviewAction = (review: IReviewDialogue): ThunkAction<void, IStore, unknown, Action> => (async (dispatch, getState) => {
  const {
    auth: {auth0Client}
  } = getState();
  const isAuthenticated = await auth0Client.isAuthenticated();
  const actionKey = removeReview;

  if (isAuthenticated) {
    dispatch(startLoadingAction(actionKey));
    dispatch(clearErrorAction(actionKey));
    try {
      await removeDialogueApi(review.dialogId);
      dispatch(loadReviewsAction());
      dispatch(finishLoadingAction(actionKey));
    } catch (e) {
      console.log('removeReview', e);
      dispatch(finishLoadingAction(actionKey));

      dispatch(errorHandler({
        toastKey: ToastKey.RemoveReviewAction,
        actionKey,
        error: e
      }));
    }
  }
});
