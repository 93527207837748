import axios, {AxiosResponse} from 'axios';
import {IReviewDialogue, IReviewType} from '../review/interfaces';

export const getReviewTypesApi = (): Promise<AxiosResponse<IReviewType[]>> => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/xpedition/review/type`
  );
};

export const getReviewsApi = (): Promise<AxiosResponse<IReviewDialogue[]>> => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/xpedition/review/list`
  );
};

export const getReviewApi = (id: number): Promise<AxiosResponse<IReviewDialogue>> => {
  return axios.get(
    `${process.env.REACT_APP_API_URL}/xpedition/review/${id}`
  );
};

export const createReviewApi = (review: IReviewDialogue, lang: string): Promise<AxiosResponse<IReviewDialogue>> => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/xpedition/review/add`,
    {
      name: review.dialogName,
      xpeditionId: review.xpeditionId,
      userDate: review.dialogUserDate,
      language: lang
    }
  );
};

export const editReviewApi = (review: IReviewDialogue): Promise<AxiosResponse<{ value: string }>> => {
  return axios.post(
    `${process.env.REACT_APP_API_URL}/xpedition/review/edit`,
    {
      id: review.dialogId,
      name: review.dialogName
    }
  );
};
