import React, {useEffect, useRef, useState} from 'react';
import {Navigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {IStore} from 'redux/interface';
import {finishLoginAction} from 'redux/auth/authAsyncActions';

import Loader from 'components/loader';

import {RoutesUrl} from 'utils/constants/routeConstants';

import './styles.scss';

export const Callback = (): JSX.Element => {
  const {
    auth: {
      auth0User,
      userProfile
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const [redirectUri, setRedirectUri] = useState<string>(RoutesUrl.Home);
  const shouldRedirect = useRef(true);

  useEffect(() => {
    if (shouldRedirect.current) {
      shouldRedirect.current = false;
      setTimeout(() => dispatch(finishLoginAction()), 1000);
      const searchParams = new URLSearchParams(window.location.search);
      const redirectUriValue = searchParams.get('appRedirectUri');

      if (redirectUriValue) {
        setRedirectUri(redirectUriValue);
      }
    }
  }, []);

  if (auth0User && userProfile) {
    if (redirectUri && redirectUri !== '') {
      return <Navigate to={redirectUri}/>;
    }
  }

  return <div className="callback container">
    <div className="callback__loader">
      <Loader/>
    </div>
  </div>;
};
