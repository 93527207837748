import React, {useCallback, useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {ReactComponent as IconUp} from '../../../assets/image/library/chevron-up.svg';
import {ReactComponent as IconDown} from '../../../assets/image/library/chevron-down.svg';
import {Collapse} from 'react-collapse';

import './styles.scss';
import {useDispatch, useSelector} from 'react-redux';
import {IStore} from '../../../redux/interface';
import {getJournalStats, getJournalStatValue, journalStatsAsyncActions} from '../../../redux/pageAsyncActions';
import {JournalStatValues} from './JournalStatsValues';
import {JournalStatSymptomValues} from './JournalStatSymptomValue';
import {JournalPieChart} from './JournalPieChart';

export const JournalStats: React.FunctionComponent = () => {
  const {
    journal_stats: {
      commonStats,
      symptomStats,
      values
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const symptomStatCode = 'ST_TOTAL_NUMBER_SEL_SYMPTOMS';
  const pieChartStatCodes = ['ST_TOTAL_NUMBER_ENTITIES_TIME_DAY', 'ST_TOTAL_NUMBER_ENTITIES_DAY_WEEK'];

  useEffect(() => {
    dispatch(journalStatsAsyncActions[getJournalStats]());
  }, []);

  useEffect(() => {
    commonStats.forEach((stat) => {
      dispatch(journalStatsAsyncActions[getJournalStatValue](stat.code));
    });
  }, [commonStats]);

  useEffect(() => {
    symptomStats.forEach((stat) => {
      dispatch(journalStatsAsyncActions[getJournalStatValue](stat.code));
    });
  }, [symptomStats]);

  const onClick = useCallback(
    () => setIsCollapseOpen(!isCollapseOpen),
    [isCollapseOpen]
  );

  return <div className="stats">
    <button className="stats__header" aria-expanded={isCollapseOpen} onClick={onClick}>
      <div className="stats__title">
        <FormattedMessage
          id={'gritx.journals.stats.header'}
          defaultMessage={'Statistics'}
        />
      </div>
      {isCollapseOpen ? <IconUp className="stats__arrow" /> : <IconDown className="stats__arrow" />}
    </button>
    <Collapse
      isOpened={isCollapseOpen}>
      <div className="stats__content">
        <>
          <div className="stats__values">
            {commonStats?.filter(v => pieChartStatCodes.includes(v.code))
              .map(v => {
                return values[v.code] && values[v.code].length
                  ? <JournalPieChart values={values[v.code]} name={v.name}/>
                  : <></>;
              })}
            {commonStats?.filter(v => !pieChartStatCodes.includes(v.code))
              .map(v => {
                return values[v.code] && values[v.code].length
                  ? <JournalStatValues values={values[v.code]} name={v.name}/>
                  : <></>;
              })}
            {values[symptomStatCode] && <JournalStatSymptomValues values={values[symptomStatCode]} name={'Symptoms'}/>}
          </div>
        </>
      </div>
    </Collapse>
  </div>;
};
