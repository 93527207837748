import {IReviewDialogue, IReviewType} from './interfaces';
import {IAction} from '../interface';
import {InferActionsTypes} from '../helpers';

export const SET_REVIEW_TYPES = 'SET_REVIEW_TYPES';
export const setReviewTypes = (types: IReviewType[]): IAction<IReviewType[]> => ({
  type: SET_REVIEW_TYPES,
  payload: types
});

export const CLEAR_REVIEW = 'CLEAR_REVIEW';
export const clearReview = (): IAction<null> => ({
  type: CLEAR_REVIEW,
  payload: null
});

export const SET_REVIEWS = 'SET_REVIEWS';
export const setReviews = (reviews: IReviewDialogue[]): IAction<IReviewDialogue[]> => ({
  type: SET_REVIEWS,
  payload: reviews
});

export const SET_REVIEW = 'SET_REVIEW';
export const setReview = (review: IReviewDialogue): IAction<IReviewDialogue> => ({
  type: SET_REVIEW,
  payload: review
});

const reviewActions = {
  setReviewTypes,
  setReviews,
  clearReview,
  setReview
};

export type ReviewActionsType = InferActionsTypes<typeof reviewActions>
